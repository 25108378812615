/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Select, Typography, Button, Card, Avatar, Space, Input, InputNumber, Progress, Form, Divider, Modal, Image, notification, Spin, Tooltip, DatePicker } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import { useSelectSearch } from 'Hooks/Search';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import URLS from 'Routes/constants';
import { useNewPaymentState } from 'App/Pages/Payments/NewPayment/store';
import { AiOutlineClose } from 'react-icons/ai';
import { FaInfoCircle, FaLock, FaRegCalendarAlt, FaRegCheckCircle, FaUser } from 'react-icons/fa';
import { useEffect, useMemo, useRef } from 'react';
import { colors } from 'Constants/Style';
import { FLAGS } from 'Constants/Images';
import { useApiRequest } from 'Hooks/API';
import moment from 'moment';
import { accountingFormat, getLocalOrAllCurrencyCrossBorderPurposeCode, numberFormat } from 'Utils';
import { useRecipientDetailsState } from 'App/Pages/Payments/RecipientList/RecipientDetails/store';
import { useLoginState, useLoginStore } from "App/Pages/Login/store";
import axios from "axios";
import { APIROUTES } from 'Constants/ApiRoutes';
import YapilyCallbackComponent from 'App/Pages/Wallet/FundWallet/components/YapilyCallbackComponent';
import { ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE, ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE, INR_ALLOWED_SELL_CURRENCIES } from 'Constants/Common';
import { OPT_FUNDING_METHOD, OPT_TTC_SELF_QUOTE_STATUS_ID } from 'Constants/Opts';
import { useCheckAccountSuspensionQuery, useIsApproverQuery, usePaymentDetailsQuery, useRecipientsQuery } from 'Hooks/Queries';
import { checkIfCrossBorderPurposeRecipient } from 'Utils';
import { useGetCurrencyRulesQuery } from 'Hooks/Queries/useGetCurrencyRulesQuery';
import { useGetPaymentPurposeCode } from 'Hooks/Queries/useGetPaymentPurposeCode';
import FullScreenModal from 'App/Components/FullScreenModal';
import RecipientDetails from '../RecipientList/RecipientDetails';
import { cloneDeep } from 'lodash';

function NewPayment() {
    const [form] = Form.useForm();
    const fundingMethod = Form.useWatch('fundingMethod', form);
    const selectedPurposeCode = Form.useWatch('crossBorderPaymentPurposeCode', form);
    const filterSelect = useSelectSearch();
    const navigate = useNavigate();
    const getAuthHeader = useLoginState(state => state.getAuthHeader);
    const recipient = useNewPaymentState(state => state.recipient);
    const setState = useNewPaymentState(state => state.setState);
    const setRecipientDetailsState = useRecipientDetailsState(state => state.setState);
    const currencies = useNewPaymentState(state => state.currencies);
    const commonCurrencies = useNewPaymentState(state => state.commonCurrencies);
    const config = useLoginStore(state => state.config);
    const showPaymentDetails = useNewPaymentState(state => state.showPaymentDetails);
    const modalOpen = useNewPaymentState(state => state.modalOpen);
    const modalType = useNewPaymentState(state => state.modalType);
    const fundingCurrency = useNewPaymentState(state => state.fundingCurrency);
    const paymentPurpose = Form.useWatch('paymentPurpose', form);
    const paymentPurposeOther = Form.useWatch('paymentPurposeOther', form);
    const currency = Form.useWatch('currency', form);
    const paymentReference = Form.useWatch('paymentReference', form);
    const displayRate = useNewPaymentState(state => state.displayRate);
    const quoteUuid = useNewPaymentState(state => state.quoteUuid);
    const displayRateInversed = useNewPaymentState(state => state.displayRateInversed);
    const setAmount = useNewPaymentState(state => state.setAmount);
    const fee = useNewPaymentState(state => state.fee);
    const firstDate = useNewPaymentState(state => state.firstDate);
    const recipientReceives = Form.useWatch('recipientReceives', form);
    const youSend = Form.useWatch('youSend', form);
    const settlementMethod = useNewPaymentState(state => state.settlementMethod);
    const seconds = useNewPaymentState(state => state.seconds);
    const fullPageSpinner = useNewPaymentState(state => state.fullPageSpinner);
    const handleOnFinishLoading = useNewPaymentState(state => state.handleOnFinishLoading);
    const acceptQuoteLoading = useNewPaymentState(state => state.acceptQuoteLoading);
    const quoteAccepted = useNewPaymentState(state => state.quoteAccepted);
    const disabledMethod = useNewPaymentState(state => state.disabledMethod);
    const volopaAccountHolderName = useNewPaymentState(state => state.volopaAccountHolderName);
    const volopaAccountSortCode = useNewPaymentState(state => state.volopaAccountSortCode);
    const volopaAccountNumber = useNewPaymentState(state => state.volopaAccountNumber);
    const volopaAccountCountry = useNewPaymentState(state => state.volopaAccountCountry);
    const volopaAccountAddress = useNewPaymentState(state => state.volopaAccountAddress);
    const volopaAccountName = useNewPaymentState(state => state.volopaAccountName);
    const volopaAccountPaymentRef = useNewPaymentState(state => state.volopaAccountPaymentRef);
    const volopaAccountIban = useNewPaymentState(state => state.volopaAccountIban);
    const volopaAccountSwift = useNewPaymentState(state => state.volopaAccountSwift);
    const volopaAccountNumberType = useNewPaymentState(state => state.volopaAccountNumberType);
    const volopaAccountSortCodeLabel = useNewPaymentState(state => state.volopaAccountSortCodeLabel);
    const volopaAccountDetails = useNewPaymentState(state => state.volopaAccountDetails);
    const fundingLimitReached = useNewPaymentState(state => state.fundingLimitReached);
    const resetRecipient = useNewPaymentState(state => state.resetRecipient);
    const presetPayment = useNewPaymentState(state => state.presetPayment);
    const showPaymentsUnder = useNewPaymentState(state => state.showPaymentsUnder);
    const showPaymentsOver = useNewPaymentState(state => state.showPaymentsOver);
    const approvalRequired = useNewPaymentState(state => state.approvalRequired);
    const draftLoading = useNewPaymentState(state => state.draftLoading);
    const deletingDraft = useNewPaymentState(state => state.deletingDraft);
    const resetState = useNewPaymentState(state => state.resetState);
    const dates = useNewPaymentState(state => state.dates);
    const scheduled = useNewPaymentState(state => state.scheduled);
    const scheduleDate = Form.useWatch('scheduleDate', form);
    const weConvert = useNewPaymentState(state => state.weConvert);
    const disableEasyTransfer = useNewPaymentState(state => state.disableEasyTransfer);
    const fundFromCollections = useNewPaymentState(state => state.fundFromCollections);
    const disableScheduling = useNewPaymentState(state => state.disableScheduling);
    const repeat = useNewPaymentState(state => state.repeat);
    const repeatFundingMethod = useNewPaymentState(state => state.repeatFundingMethod);
    const TransactionComplete = useNewPaymentState((state) => state.TransactionComplete);
    const transactionStatus = useNewPaymentState((state) => state.transactionStatus);
    const selectedCurrency = useNewPaymentState((state) => state.selectedCurrency);
    const currencyLimitUKOrEEA = useNewPaymentState((state) => state.currencyLimitUKOrEEA);
    const showInvoiceAndDateFields = useNewPaymentState((state) => state.showInvoiceAndDateFields);
    const totalDailyLimitReached = useNewPaymentState((state) => state.totalDailyLimitReached);
    const PaymentPurposeCodes = useNewPaymentState((state) => state.PaymentPurposeCodes);
    const viewDetailsModalOpen = useNewPaymentState((state) => state.viewDetailsModalOpen);
    const recipients = useNewPaymentState((state) => state.recipients);
    const purposeOptions = ["Payment of Invoice(s)", "Internal Payment to Company own account", "Payment of Expenses", "Payment of Salaries", "Payment of Commissions", "Disbursement of Dividends", "Director/Employee loan", "Other"];
    const timeout = useRef();
    const inputTimeout = useRef();
    const { mapId } = useParams();
    const { data: reviewPaymentDetails, isFetching: reviewPaymentDetailsLoading, isError: reviewPaymentDetailsError } = usePaymentDetailsQuery(mapId);
    const { data: isApprover, isFetching: isApproverLoading } = useIsApproverQuery();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const setStateAlert = useLoginStore(store => store.setStore);
    const { data: allRecipients, isLoading: fetchingRecipients } = useRecipientsQuery(!mapId);
    const { isLoading: fetchingPaymentCodes } = useGetPaymentPurposeCode();
    const { data: accountStatus, isFetching: accountStatusFetching } = useCheckAccountSuspensionQuery();
    const { data: currencyRules } = useGetCurrencyRulesQuery();

    const purposeOptionsStructure = purposeOptions.map((item, i) =>
        <Select.Option key={i} value={item}>{item !== "Other" ? item : "Other (please fill in the Other Reason field below)"}</Select.Option>
    );

    const crossBorderPaymentPurposeCode = useMemo(() => {
        return getLocalOrAllCurrencyCrossBorderPurposeCode(PaymentPurposeCodes, recipient?.bank_country, recipient?.currency)
    }, [recipient?.bank_country, recipient?.currency]);

    const checkIfCrossBorderPurposeApplicable = useMemo(() => checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, recipient).length ? true : false,
        [PaymentPurposeCodes, recipient?.bank_country, recipient?.currency]);

    const apiRequest = useApiRequest();

    const abortController = useRef();

    const validatorState = useRef();

    useEffect(() => {
        if (!!accountStatus?.suspended_accounts?.payments) {
            notification.destroy();
            notification.error({
                description: 'This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed.',
                placement: 'top'
            })
        }
    }, [accountStatus]);

    useEffect(() => {
        if (fundingMethod === OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT) {
            setState({ disableScheduling: true });
            form.resetFields(['scheduleDate']);
        } else {
            setState({ disableScheduling: false })
        }
    }, [fundingMethod])

    useEffect(() => {
        if (currencyRules) {
            setState({ selectedCurrency: currencyRules.find(item => item?.upper_limit_ccy === recipient?.currency) });
        }
    }, [currencyRules, recipient?.currency]);

    useEffect(() => {
        if (reviewPaymentDetailsError) {
            resetData();
            setState({ fullPageSpinner: true });
            notification.error({ message: 'Something Went Wrong', description: 'Draft payment was deleted.', placement: 'top' });
        }
        if (reviewPaymentDetails && !reviewPaymentDetailsLoading && !reviewPaymentDetailsError) {
            if (reviewPaymentDetails?.opt_tcc_self_quote_status_id === OPT_TTC_SELF_QUOTE_STATUS_ID?.DRAFTED) {
                setState({
                    recipient: reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.tcc_beneficiary,
                    setAmount: reviewPaymentDetails?.side,
                    settlementMethod: reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.payment_type,
                    scheduled: reviewPaymentDetails?.scheduled,
                    showPaymentDetails: true
                });
                form.setFieldsValue({
                    recipientReceives: reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.beneficiary_amt,
                    paymentReference: reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.payment_reference,
                    currency: reviewPaymentDetails?.sell_currency,
                    youSend: reviewPaymentDetails?.calc_client_send,
                    email: reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.tcc_beneficiary?.email,
                    ccEmail: reviewPaymentDetails?.payment_cc_email?.[0]?.cc_email,
                    scheduleDate: reviewPaymentDetails?.delivery_date && moment(reviewPaymentDetails?.delivery_date),
                    fundingMethod: reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.fund_method_id
                });
            } else {
                navigate(URLS.DraftPayments, { replace: true });
            }
        }
    }, [reviewPaymentDetails, reviewPaymentDetailsLoading]);

    useEffect(() => {
        if (reviewPaymentDetails && recipient && paymentPurpose && !quoteUuid) {
            refreshQuote();
        }
    }, [reviewPaymentDetails, recipient, paymentPurpose]);

    useEffect(() => {
        if (mapId && !quoteUuid) {
            setState({ draftLoading: true })
        } else if (mapId && quoteUuid) {
            setState({
                draftLoading: false,
                modalOpen: true,
                modalType: 'fundingConfirmation'
            })
        }
    }, [quoteUuid])


    const getPaymentDetailRetry = async () => {
        try {
            setState({ fullPageSpinner: true });
            const timeout = setTimeout(() => {
                clearInterval(interval);
                setState({ fullPageSpinner: false, TransactionComplete: true, transactionStatus: 'FAILED' });
            }, 120000);
            const interval = setInterval(async () => {
                const response = await apiRequest(`${APIROUTES.post.get_payment_detail}`, 'POST', { paymentRequestId: query.get('paymentRequestId') });
                if (response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status === 'COMPLETED' || response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status === 'PENDING') {
                    setState({ fullPageSpinner: false, TransactionComplete: true, transactionStatus: response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status });
                    clearInterval(interval);
                    clearTimeout(timeout)
                    return;
                }
            }, 5000);
        } catch (error) {
            getPaymentDetailRetry()
        }
    };

    useEffect(() => {
        validatorState.current = false;
        if (youSend && parseFloat(youSend) < 10) {
            validatorState.current = true;
        } else {
            validatorState.current = false;
        }
    }, [youSend])

    useEffect(() => {
        if (selectedCurrency && Object.keys(selectedCurrency).length) {
            let currencyLimitUKOrEEATemp = false
            let total_daily_limit = false
            // This condition is for Total Daily limit of INR uisng currencyRule API
            if (selectedCurrency?.currency_id === 12 && selectedCurrency?.total_daily_limit && parseFloat(recipientReceives) > parseFloat(selectedCurrency?.total_daily_limit)) {
                total_daily_limit = true
            }
            // This Condition is for Checking Upper Limit of Currency or Invoice Limit uisng currencyRule API
            if (selectedCurrency?.upper_limit && recipientReceives && parseFloat(recipientReceives) > parseFloat(selectedCurrency?.upper_limit)) {
                currencyLimitUKOrEEATemp = true;
            }
            setState({ currencyLimitUKOrEEA: currencyLimitUKOrEEATemp, totalDailyLimitReached: total_daily_limit })
        }
    }, [recipientReceives]);

    useEffect(() => {
        if (checkIfCrossBorderPurposeApplicable && selectedPurposeCode) {
            let currentCrossBorderPurposeCode = crossBorderPaymentPurposeCode?.find((c) => c.code === selectedPurposeCode) || { trade_related: false };
            if (currentCrossBorderPurposeCode?.trade_related) {
                setState({ showInvoiceAndDateFields: true })
            } else {
                setState({ showInvoiceAndDateFields: false })
            }
        }
    }, [selectedPurposeCode])

    useEffect(() => {
        if (!mapId) {
            const amountLimit = youSend ? youSend : 0
            if (
                ['GBP', 'EUR'].includes(currency) &&
                amountLimit < 75000 && config?.easy_transfer_allowed &&
                (
                    !scheduleDate ||
                    scheduleDate?.isSame(dates?.next_day_conversion_date, 'day') ||
                    scheduleDate?.isSame(dates?.first_conversion_date, 'day')
                )
            ) {
                if (fundingMethod !== OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT) {
                    form.setFieldValue('fundingMethod', OPT_FUNDING_METHOD.EASY_TRANSFER)
                }
                setState({ disableEasyTransfer: false })
            } else {
                if (fundingMethod !== OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT) {
                    form.setFieldValue('fundingMethod', OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS)
                }
                setState({ disableEasyTransfer: true })
            }

            if (repeat) {
                form.setFieldValue('fundingMethod', repeatFundingMethod)
                setState({ repeat: false, disableEasyTransfer: !(['GBP', 'EUR'].includes(youSend) && amountLimit < 75000) && config?.easy_transfer_allowed ? true : false })
            }
        }
    }, [currency, scheduleDate]);

    useEffect(() => {
        if (!mapId) {
            if (fundingMethod === OPT_FUNDING_METHOD.EASY_TRANSFER && youSend >= 75000 && config?.easy_transfer_allowed) {
                form.setFieldValue('fundingMethod', OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS)
                setState({ disableEasyTransfer: true })
            } else if (
                ['GBP', 'EUR'].includes(currency) &&
                config?.easy_transfer_allowed &&
                (
                    !scheduleDate ||
                    scheduleDate?.isSame(dates?.next_day_conversion_date, 'day') ||
                    scheduleDate?.isSame(dates?.first_conversion_date, 'day')
                )
            ) {
                setState({ disableEasyTransfer: false })
            }
        }
    }, [youSend])

    useEffect(() => {
        form.setFieldsValue({ email: recipient ? recipient.email : '' });
        if (query.get('paymentRequestId')) {
            getPaymentDetailsYapily();
        }
        if (fundFromCollections) {
            form.setFieldValue('fundingMethod', OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT)
            setState({ fundFromCollections: false })
        }
        if (!resetRecipient && recipient) {
            setState({ resetRecipient: true });
            if (presetPayment && !recipient?.deleted) {
                form.setFieldsValue({
                    recipientReceives: parseFloat(presetPayment.beneficiary_amt).toFixed(2),
                    paymentReference: presetPayment.payment_reference,
                    currency: presetPayment.tcc_quote_b_b_self.sell_currency,
                    youSend: presetPayment.tcc_quote_b_b_self.calc_client_send,
                    email: recipient.email
                });
                setState({
                    setAmount: presetPayment.tcc_quote_b_b_self.fix_client_send !== null ? "sell" : "buy",
                    settlementMethod: presetPayment.payment_type,
                    showPaymentDetails: true
                });
            }
            if (!!recipient?.deleted) {
                resetData();
                notification.error({ message: 'Error', description: 'The recipient has been deleted.', placement: 'top' })
            }
        }
        checkedRateAvailability();
        return () => { clearTimeout(timeout.current); form?.resetFields(); resetState(); }
    }, [])

    useEffect(() => {
        if (recipient) {
            let bothRegularAndPriority = false;
            if (recipient.payment_types.includes("regular") && recipient.payment_types.includes("priority")) {
                bothRegularAndPriority = true;
            }
            if (!bothRegularAndPriority && recipient.payment_types.includes("regular")) {
                setState({
                    settlementMethod: 'regular',
                    disabledMethod: 'priority'
                });
            }
            if (!bothRegularAndPriority && recipient.payment_types.includes("priority")) {
                setState({
                    settlementMethod: 'priority',
                    disabledMethod: 'regular'
                });
            }
            getCurrencies();

            let fillPaymentPurposeVal = '';

            if (!!presetPayment?.payment_reason) {
                fillPaymentPurposeVal = presetPayment?.payment_reason || '';
            } else if (!!reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.payment_reason) {
                fillPaymentPurposeVal = reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.payment_reason || '';
            } else {
                fillPaymentPurposeVal = recipient?.payment_reason || '';
            }

            if (fundingCurrency) {
                form.setFieldValue('currency', fundingCurrency);
            }

            fillPaymentPurpose(fillPaymentPurposeVal);
        }
    }, [recipient])

    useEffect(() => {
        if (currency && recipient) {
            if (!!youSend || !!recipientReceives) {
                !mapId && getTempQuote();
            }
            getDates();
            getPaymentDetails();
        }
    }, [currency, recipient])

    useEffect(() => {
        if (currency && (!!recipientReceives || !!youSend)) {
            !mapId && getTempQuote();
        }
    }, [settlementMethod, scheduleDate, fundingMethod])

    useEffect(() => {
        if (!quoteAccepted) {
            if (seconds > 0 && currency && recipient) {
                seconds === config.b2b_expires_in && clearTimeout(timeout.current)
                timeout.current = setTimeout(() => setState({ seconds: seconds - 1 }), 1000);
            }
            if (seconds === 0 && currency && recipient && (!!recipientReceives || !!youSend)) {
                refreshQuote();
            }
        }
    }, [seconds]);

    useEffect(() => {
        if (fundingCurrency && allRecipients && !recipients) {
            const tempRecipients = cloneDeep(allRecipients);
            const filteredIds = allRecipients?.recipientsData.filter(rec => rec.currency !== fundingCurrency).map(rec => rec.id);
            const filteredFrequentRecipiets = allRecipients.frequentRecipients.filter(rec => filteredIds.includes(rec.id));
            const filteredGroupedRecipients = allRecipients.recipientsGrouped.map(grp => {
                const filtered = grp.recipients.filter(rec => filteredIds.includes(rec.value));
                grp.recipients = filtered;
                return grp
            }).filter(grp => grp.recipients.length > 0);

            tempRecipients.frequentRecipients = filteredFrequentRecipiets;
            tempRecipients.recipientsGrouped = filteredGroupedRecipients;
            setState({ recipients: tempRecipients })
        } else if (!fundingCurrency && allRecipients && !recipients) {
            setState({ recipients: allRecipients })
        }
    }, [fundingCurrency, allRecipients, recipients])


    const getPaymentDetailsYapily = async () => {
        try {
            setState({ fullPageSpinner: true });
            const response = await apiRequest(`${APIROUTES.post.get_payment_detail}`, 'POST', { paymentRequestId: query.get('paymentRequestId') });
            if (response && response?.success) {
                setState({ TransactionComplete: true, transactionStatus: response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status, fullPageSpinner: false });
                return;
            }
            getPaymentDetailRetry()
        } catch {
            getPaymentDetailRetry()
        }
    }

    const goToSpecificRecipient = () => {
        let tempRecipient = {};
        tempRecipient.fullDetails = recipient;
        setRecipientDetailsState({
            recipient: tempRecipient
        });
        setState({ viewDetailsModalOpen: true });
    }

    const getPaymentDetails = async () => {
        let response = await apiRequest("tcc/fund/account/payment?currency=" + currency);
        if (response && response.data && (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") || response.data.result.settlement_accounts[0])) {
            setState({
                volopaAccountDetails: response.data.result
            });
            if (response.data.result.settlement_accounts[0]) {
                updateFundingDetails(null, true, response.data.result);
            }
            if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                updateFundingDetails(null, true, response.data.result);
            }
            if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") && response.data.result.settlement_accounts[0]) {
                setState({
                    showPaymentsUnder: true,
                    showPaymentsOver: true,
                    fundingLimitReached: true
                });
            } else {
                if (response.data.result.settlement_accounts[0]) {
                    setState({
                        showPaymentsOver: true,
                        showPaymentsUnder: false
                    });
                }
                if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                    setState({
                        showPaymentsUnder: true,
                        showPaymentsOver: false
                    });
                }
            }
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading the Volopa funding details for this currency', placement: 'top' });
        }
    }

    const getCurrencies = async () => {
        const response = await apiRequest("currency?$limit=500&active=1");

        if (response && response.data) {
            let currArray = config.is_eea === 1 ? ['BHD', 'BGN', 'CNY', 'ILS', 'KWD', 'OMR', 'QAR', 'RUB', 'THB', 'UGX', 'AED', 'INR', 'KES', 'RON', 'SAR', 'TRY', 'MXN', 'CZK'] : ['BHD', 'BGN', 'CNY', 'ILS', 'KWD', 'OMR', 'QAR', 'RUB', 'UGX', 'AED', 'INR', 'KES', 'RON', 'SAR', 'TRY', 'MXN', 'CZK'];
            let cur = response.data
                .filter((data) => data.currency !== recipient.currency && (!currArray.includes(data.currency)))
                .sort((a, b) => a.currency.localeCompare(b.currency));

            // Add condition for allowed sell currencies for INR only
            if (recipient?.currency === 'INR') {
                cur = cur.filter((data) => INR_ALLOWED_SELL_CURRENCIES.includes(data.currency));
            }

            cur = cur.map((val, key) => {
                val.key = key;
                val.flag = FLAGS[val.currency];
                val.label = val.title + ' (' + val.currency + ')';
                val.name = val.currency;
                val.value = val.currency;
                return val;
            });
            let commonCur = cur
                .filter((data) => data.sort_order_buy !== 999 && data.currency !== recipient.currency)
                .sort((a, b) => a.sort_order_buy - b.sort_order_buy);
            setState({ currencies: cur, commonCurrencies: commonCur });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading currencies', placement: 'top' });
        }
    };

    const checkedRateAvailability = async () => {
        try {
            const response = await apiRequest(APIROUTES.get.exchange_out_of_hours);
            if (response && response?.data?.success) {
                setStateAlert({ outageAlert: undefined });
            } else {
                setStateAlert({ outageAlert: 'Please note: New Payments cannot be submitted at the moment' });
            }
        } catch (e) {
            setStateAlert({ outageAlert: 'Please note: New Payments cannot be submitted at the moment' });
        } finally {
        }
    }

    const getDates = async () => {
        if (recipient && recipient.currency && currency) {
            const response = await apiRequest("tcc/conversion/dates/all?currencyPair=" + currency + recipient.currency);
            if (response && response.data) {
                setState({
                    firstDate: moment(response.data.result.first_conversion_date).format('DD-MM-YYYY'),
                    dates: response?.data?.result
                });
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading dates', placement: 'top' });
            }
        }
    }

    const finalizeQuote = async () => {
        if (recipient && recipient.currency && currency) {
            setState({ handleOnFinishLoading: true });
            // Reason might change if cross border purpose code needed
            let data_reason = paymentPurpose === "Other" ? paymentPurposeOther : paymentPurpose;
            let data_purpose_code = null;
            // @Depreciated - Handle only cn/cny for now
            // Now handle for other currencies as well
            if (ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE.includes(recipient?.bank_country) || ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE.includes(`${recipient?.bank_country}_${recipient?.currency}`)) {
                let pcode = form.getFieldValue('crossBorderPaymentPurposeCode');
                let pcodeLabel = crossBorderPaymentPurposeCode?.find((c) => c.code === pcode)?.label || 'Cross-border goods trade';
                data_reason = pcodeLabel;
                data_purpose_code = pcode;
            }

            let data = {
                uuid: quoteUuid,
                reason: data_reason,
                payment_reason: data_reason,
                payment_purpose_code: data_purpose_code,
                payment_reference: paymentReference
            }

            if (showInvoiceAndDateFields) {
                data.invoice_number = form.getFieldValue('invoiceNumber');
                data.invoice_date = form.getFieldValue('invoiceDate');
            }

            const response = await apiRequest("exchange/b2b/self/quote/temp/update", "POST", data);
            if (response && response.data) {
                setState({ approvalRequired: response?.data?.status_id === OPT_TTC_SELF_QUOTE_STATUS_ID.APPROVAL_REQUIRED ? true : false })
                setQuote(response, data, false);
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with receiving a quote', placement: 'top' });
            }
            setState({ handleOnFinishLoading: false });
        }
    }

    const refreshQuote = async () => {
        if (recipient && recipient.currency && currency) {
            clearTimeout(timeout.current);
            setState({ handleOnFinishLoading: true });

            let data = {
                uuid: quoteUuid || mapId
            }
            const response = await apiRequest("exchange/b2b/self/quote/requote/uuid", "POST", data);
            if (response && response.data) {
                setQuote(response, data);
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with receiving a quote', placement: 'top' });
            }
            setState({ handleOnFinishLoading: false });
        }
    }

    const getTempQuote = async () => {
        if (!!recipient && !!recipient?.currency && !!currency && (!!youSend || !!recipientReceives)) {
            clearTimeout(timeout.current);
            setState({ fullPageSpinner: true });
            const authorization = await getAuthHeader();
            let recipientObject = {};
            recipientObject.beneficiary_id = recipient.id;
            recipientObject.amount = recipientReceives;
            recipientObject.payment_type = settlementMethod;
            recipientObject.payment_reason = '';
            recipientObject.payment_reference = '';

            let recipientListArray = [];
            recipientListArray[0] = recipientObject;
            let data = {
                ccySell: currency,
                ccyBuy: recipient.currency,
                amount: setAmount === "sell" ? youSend : recipientReceives,
                recipientsList: recipientListArray,
                conversionType: 'earliest',
                singlePayment: true,
                side: setAmount,
                fundMethodId: fundingMethod,
            }

            if (scheduleDate) {
                if (scheduleDate?.isSame(dates?.next_day_conversion_date, 'day')) {
                    data.conversionType = 'next_day'
                    setState({ scheduled: false });
                } else if (scheduleDate?.isSame(dates?.first_conversion_date, 'day')) {
                    data.conversionType = 'earliest'
                    setState({ scheduled: false });
                } else {
                    data.delivery_date = scheduleDate?.format("YYYY-MM-DD");
                    data.scheduled = true;
                    setState({ scheduled: true });
                }
            } else {
                setState({ scheduled: false });
            }
            if (setAmount) {
                try {
                    abortController?.current?.abort();
                    abortController.current = new AbortController();
                    const response = await axios({
                        method: "POST",
                        url: (process.env.REACT_APP_API_BASE_URL) + "exchange/b2b/self/quote/temp",
                        data: data,
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': '"/"',
                            'Authorization': authorization
                        },
                        signal: abortController.current.signal,
                    })

                    if (response?.data) {
                        setQuote(response?.data, data);
                    } else {
                        notification.error({ message: 'Something Went Wrong', description: 'There was an issue with receiving a quote', placement: 'top' });
                    }
                } catch (e) {
                    if (e?.response?.data?.error?.message) {
                        notification.destroy();
                        notification.error({ message: e?.response?.data?.error?.message, placement: 'top' });
                    }
                }
            }
            setState({ fullPageSpinner: false });
        }
    }

    const setQuote = (response, data, resetTimer = true) => {
        const obj = {
            quoteUuid: response.data.uuid,
            displayRate: response.data.rate,
            displayRateInversed: response.data.inverseRate,
            firstDate: data.scheduled && data?.infoOnly ? reviewPaymentDetails?.delivery_date && moment(reviewPaymentDetails?.delivery_date).format('DD-MM-YYYY') : moment(response?.data?.delivery_date || response.data.expected_delivery).format('DD-MM-YYYY'),
            weConvert: response?.data?.weConvert,
            fee: response?.data?.PaymentFees,
            showPaymentDetails: true
        }

        if (resetTimer) {
            obj.seconds = config.b2b_expires_in === seconds ? seconds - 1 : config?.b2b_expires_in;
        } else {
            obj.modalOpen = true;
            obj.modalType = 'fundingConfirmation';
        }

        if (data?.infoOnly) {
            obj.scheduled = !!reviewPaymentDetails?.scheduled;
        }

        setState({ ...obj });
        form.setFieldsValue({
            youSend: response.data.youSend,
            recipientReceives: response.data.receive,
        })
    }

    const acceptQuote = async () => {
        setState({ acceptQuoteLoading: true });
        clearTimeout(timeout.current);
        if (form.getFieldValue("ccEmail")) {
            let ccData = {
                uuid: quoteUuid,
                cc_email: form.getFieldValue("ccEmail")
            }
            let ccResp = await apiRequest("tcc/paymentccemail", "POST", ccData);
            if (!ccResp.data) {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with saving the cc email', placement: 'top' });
            }
        }
        if (!recipient.email && form.getFieldValue("email")) {
            let mailResp = await apiRequest("tcc/beneficiary/-" + recipient.id + "?email=" + form.getFieldValue("email"), "PUT");
            if (!mailResp.data) {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with saving the recipient email', placement: 'top' });
            }
        }
        let quoteData = {
            uuid: quoteUuid,
        }

        if (mapId && !scheduled) {
            quoteData.parentQuoteId = mapId;
        }

        const sameDay = scheduleDate?.isSame(dates?.first_conversion_date, 'day');
        const nextDay = scheduleDate?.isSame(dates?.next_day_conversion_date, 'day');

        const route = (scheduled && !sameDay && !nextDay) ?
            (
                !mapId ?
                    APIROUTES.post.schedule_draft :
                    APIROUTES.post.approve_scheduled_payment
            ) :
            approvalRequired ?
                APIROUTES.post.payment_draft :
                APIROUTES.post.accept_quote_uuid;
        const response = await apiRequest(route, "POST", quoteData);
        if ((response && response.data && response.data.fxdeal) || response?.data?.success) {
            setState({ modalType: 'pendingFunds', quoteAccepted: true });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with accepting our quote', placement: 'top' });
            refreshQuote();
        }
        setState({ acceptQuoteLoading: false });
    }

    //
    const fillCrossBorderPaymentPurposeCode = () => {

        let pcode = {};
        if (!!presetPayment?.payment_purpose_code) {
            pcode = crossBorderPaymentPurposeCode?.find((c) => c.code === presetPayment?.payment_purpose_code);
        } else if (!!reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.payment_purpose_code) {
            pcode = crossBorderPaymentPurposeCode?.find((c) => c.code === reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.payment_purpose_code);
        } else {
            pcode = crossBorderPaymentPurposeCode.find((c) => c.id === (recipient?.tcc_payment_purpose_code_id || 2));
        }

        form.setFieldsValue({
            paymentPurpose: pcode?.label || `Cross-border goods trade`,
            paymentPurposeOther: '',
            crossBorderPaymentPurposeCode: pcode?.code,
        });
    }

    const fillPaymentPurpose = (reason) => {
        if (reason !== "") {
            if (!!recipient?.tcc_payment_purpose_code_id) {
                fillCrossBorderPaymentPurposeCode();
            } else {
                if (purposeOptions.includes(reason)) {
                    form.setFieldsValue({
                        paymentPurpose: reason
                    });
                } else {
                    form.setFieldsValue({
                        paymentPurpose: 'Other (please fill in the Other Reason field below)',
                        paymentPurposeOther: reason
                    });
                }
            }
        } else {
            form.setFieldsValue({
                paymentPurpose: '',
                paymentPurposeOther: ''
            });
        }
    }

    const handleSelect = (recipient) => {
        let selectedRecipient = recipients?.recipientsData?.filter(recipientData => {
            return `${recipientData.id}` === `${recipient}`;
        });
        if (selectedRecipient[0]?.email) {
            form.setFieldValue('email', selectedRecipient[0]?.email)
        }


        setState({
            recipient: selectedRecipient[0],
        });
    };

    const handleOnFinish = () => {
        finalizeQuote(); // change this to finalise quote
    }

    const updateSettlementMethod = (e) => {
        setState({ settlementMethod: e.target.textContent.toLowerCase() });
    }
    const updateRecipientRecieves = (e) => {
        const val = numberFormat(e.target.value)
        clearTimeout(inputTimeout?.current);
        clearTimeout(timeout?.current);
        if (currency && val) {
            inputTimeout.current = setTimeout(getTempQuote, 2000)
        }
    }
    const updateYouSend = (e) => {
        const val = numberFormat(e.target.value)
        clearTimeout(inputTimeout?.current);
        clearTimeout(timeout?.current);
        if (currency && val) {
            inputTimeout.current = setTimeout(getTempQuote, 2000)
        }
    }

    const updateSetAmount = (type = 'buy') => {
        setState({
            setAmount: type,
            displayRate: undefined,
            displayRateInversed: undefined,
            fullPageSpinner: false,
            weConvert: undefined,
            fee: undefined
        });
        if (type === "sell") {
            form.setFieldValue('recipientReceives', "");
        } else {
            form.setFieldValue('youSend', "");
        }
    }

    const goToDashBoard = () => {
        navigate(URLS.PaymentsDashboard);
    }

    const viewPayment = () => {
        const url = approvalRequired || scheduled ? URLS.DraftPayments : URLS.PaymentHistory;
        navigate(url);
    }


    const updateFundingDetails = (e, over, details) => {
        let sortCodeLabel = 'Sort Code';
        let volopaAccountDetailsData = details ?? volopaAccountDetails;
        if (over === false) {
            let routingCodeType = volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name,
                volopaAccountSortCode: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code,
                volopaAccountNumber: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number,
                volopaAccountCountry: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country,
                volopaAccountAddress: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address,
                volopaAccountName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name,
                volopaAccountPaymentRef: volopaAccountDetailsData?.short_reference,
                volopaAccountIban: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.iban,
                volopaAccountSwift: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bic_swift,
                volopaAccountNumberType: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: false
            });
        } else {
            let routingCodeType = volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_type_1;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_account_holder_name,
                volopaAccountSortCode: volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_value_1,
                volopaAccountNumber: volopaAccountDetailsData?.settlement_accounts[0]?.account_number,
                volopaAccountCountry: volopaAccountDetailsData?.settlement_accounts[0]?.bank_country,
                volopaAccountAddress: volopaAccountDetailsData?.settlement_accounts[0]?.bank_address,
                volopaAccountName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_name,
                volopaAccountPaymentRef: volopaAccountDetailsData?.short_reference,
                volopaAccountIban: volopaAccountDetailsData?.settlement_accounts[0]?.iban,
                volopaAccountSwift: volopaAccountDetailsData?.settlement_accounts[0]?.bic_swift,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: true
            });
        }
    }

    const resetData = () => {
        resetState();
        form.resetFields();
        clearTimeout(timeout.current);
    }

    const handleDeleteDraft = async () => {
        setState({ deletingDraft: true });
        const body = { uuid: quoteUuid }
        const res = await apiRequest(APIROUTES.post.cancel_draft, 'POST', body);
        setState({ deletingDraft: false });
        notification.destroy();
        if (res?.data?.success) {
            notification.success({
                placement: 'top',
                message: 'Draft Payment Deleted'
            })
            resetData();
            navigate(URLS.DraftPayments);
        } else {
            notification.error({
                placement: 'top',
                message: 'Error',
                description: 'Draft payment delete failed'
            })
        }
    }

    const handleDisabledDates = (date) => {
        let disabled = false;
        const invalidDates = dates?.invalid_conversion_dates && Object?.keys(dates?.invalid_conversion_dates);
        if (
            date?.isBefore(dates?.first_conversion_date, 'day') ||
            invalidDates?.includes(date?.format('YYYY-MM-DD')) ||
            date?.isAfter(moment(dates?.first_conversion_date).add(1, 'year'), 'day')
        )
            disabled = true;

        return disabled;
    }

    const handleEasyTransfer = async () => {
        try {
            setState({ acceptQuoteLoading: true });
            const body = {
                type: "FUND_TRANSFER",
                amount: youSend,
                currency: currency,
                description: paymentReference,
                fundMethodId: 2,
                uuid: quoteUuid,
            }
            const response = await apiRequest(`${APIROUTES.post.accounting_create_payment}`, 'POST', body);
            if (response.success) {
                window.location.href = response?.response?.data?.hostedUrl;
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch {
            notification.error({ message: 'Something went wrong!', placement: 'top' })
        }
        finally {
            setState({ acceptQuoteLoading: false });
        }
    }

    const handlePaymentConfirmationPage = async () => {
        try {
            setState({ fullPageSpinner: true, TransactionComplete: false });
            const response = await apiRequest(`${APIROUTES.get.easy_transfer_getBooked_payment}${query.get('paymentRequestId')}`, 'GET');
            if (response && response.success) {
                let selectedRecipient = recipients?.recipientsData?.filter(recipientData => {
                    return recipientData.id === response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.tcc_beneficiary?.id;
                });
                if (selectedRecipient[0]?.email) {
                    form.setFieldValue('email', selectedRecipient[0]?.email)
                }
                fillPaymentPurpose(selectedRecipient[0].payment_reason);
                setState({
                    modalOpen: true,
                    modalType: 'pendingFunds',
                    quoteAccepted: true,
                    quoteId: response?.data?.quote?.id,
                    displayRate: response?.data?.quote?.composite_rate?.toFixed(6),
                    displayRateInversed: response?.data?.quote?.composite_rate_inverse?.toFixed(6),
                    paymentDetails: { fundingMethod: OPT_FUNDING_METHOD.EASY_TRANSFER, recipientReceives: response?.data?.quote?.calc_client_receive, currency: response?.data?.quote?.sell_currency, youSend: response?.data?.quote?.calc_client_send },
                    currency: response?.data?.quote?.sell_currency,
                    fundingMethod: OPT_FUNDING_METHOD.EASY_TRANSFER,
                    recipient: {
                        ...selectedRecipient[0],
                        currency: response?.data?.quote?.buy_currency,
                        beneficiary_ccy: response?.data?.quote?.buy_currency,
                        beneficiary_amt: response?.data?.quote?.calc_client_send,
                        payment_types: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_type,
                        paymentPurpose: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_reason,
                        paymentReference: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_reference,
                    },
                    settlementMethod: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_type,
                    paymentReference: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_reference,
                    fee: response?.data?.quote?.additional_fees,
                    amount: response?.data?.paymentDetail?.amountToPay,
                    firstDate: moment(response?.data?.quote?.settlement_cut_off_time).format('DD-MM-YYYY'),
                    youSend: response?.data?.quote?.calc_client_send,
                    weConvert: response?.data?.quote?.calc_client_sell,
                    recipientReceives: response?.data?.quote?.calc_client_receive,
                    acceptQuoteLoading: false,
                    paymentPurpose: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_reason,
                    handleOnFinishLoading: false,
                    showPaymentDetails: true,
                    quoteUuid: response?.data?.quote?.uuid,
                })
                form.setFieldsValue({
                    recipientReceives: parseFloat(response?.data?.quote?.calc_client_receive).toFixed(2),
                    paymentPurpose: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_reason,
                    paymentPurposeOther: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_reason,
                    paymentReference: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.payment_reference,
                    currency: response?.data?.quote?.sell_currency,
                    youSend: response?.data?.quote?.calc_client_send,
                    email: selectedRecipient[0].email,
                    fundingMethod: OPT_FUNDING_METHOD.EASY_TRANSFER
                });
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch (error) {
            notification.error({ message: 'Something Went Wrong!', placement: 'top' })
        } finally {
            setState({ fullPageSpinner: false });
        }
    }

    return (
        <Spin
            spinning={
                fullPageSpinner ||
                (!mapId && fetchingRecipients) ||
                reviewPaymentDetailsLoading ||
                draftLoading ||
                isApproverLoading ||
                handleOnFinishLoading ||
                fetchingPaymentCodes ||
                accountStatusFetching
            }
            size={'large'}
        >
            {TransactionComplete ?
                <YapilyCallbackComponent
                    transactionStatus={transactionStatus}
                    goToHistoryText={transactionStatus === 'COMPLETED' ? "Payment History" : "View Payment"}
                    returnToDashboardText={"Return to Dashboard"}
                    returnToDashboard={() => { navigate(URLS.PaymentsDashboard); setState({ TransactionComplete: false, modalOpen: false }) }}
                    goToHistory={!(transactionStatus === 'COMPLETED' || transactionStatus === 'PENDING') ? handlePaymentConfirmationPage : () => { navigate(URLS.PaymentHistory); setState({ TransactionComplete: false, modalOpen: false }) }}
                />
                : <>
                    <Modal
                        open={modalOpen}
                        footer={false}
                        closable={false}
                        onCancel={() => setState({ modalOpen: false })}
                        width={1050}
                        destroyOnClose
                        maskClosable={false}
                    >
                        <Row justify='center'>
                            <Col>
                                {(modalType === 'fundingConfirmation') && (
                                    <Typography.Text className='fs-24px medium dark-green'>Payment Confirmation</Typography.Text>
                                )}
                                {modalType === 'pendingFunds' && !approvalRequired && !scheduled &&
                                    <Typography.Text type='success' className='fs-24px medium'><FaRegCheckCircle style={{ marginBottom: '-3px' }} /> Payment Booked - </Typography.Text>
                                }
                                {(modalType === 'pendingFunds' && !!approvalRequired) && (
                                    <Typography.Text type='warning' className='fs-24px medium'>Submitted for Approval</Typography.Text>
                                )}
                                {modalType === 'pendingFunds' && !approvalRequired && !scheduled && (
                                    <Typography.Text type='warning' className='fs-24px medium'>Pending Funds</Typography.Text>
                                )}
                                {modalType === 'pendingFunds' && !approvalRequired && !!scheduled && (
                                    <Typography.Text type='success' className='fs-24px medium'>Payment Scheduled</Typography.Text>
                                )}
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-10">
                            <Col>
                                <Typography.Text className="light fs-18px dark-green center-align-text">
                                    If you do not have sufficient balance in your account, please make a payment to the following account:
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row className='m-t-10' gutter={[12, 12]} justify='space-between'>
                            {showPaymentsUnder && <Col flex='auto'>
                                <Row justify='center'>
                                    <Col>
                                        <Button
                                            size='small'
                                            type='primary'
                                            className={!fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                            ghost={fundingLimitReached}
                                            onClick={(e) => updateFundingDetails(e, false)}
                                        >
                                            {config.is_eea === 1 ? "Regular/SEPA Payment Up To A Value Of EUR 250,000" : "Regular / Faster Payment Up To A Value Of £250,000.00"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>}
                            {showPaymentsOver && <Col flex='auto'>
                                <Row justify='center'>
                                    <Col>
                                        <Button
                                            size='small'
                                            type='primary'
                                            ghost={!fundingLimitReached}
                                            className={fundingLimitReached ? 'bg-light-green b-g medium' : ''}
                                            onClick={(e) => updateFundingDetails(e, true)}
                                        >
                                            {config.is_eea === 1 ? "Payments Over EUR 250,00 Or SWIFT/TARGET2 Payments" : "Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>}
                        </Row>
                        <Row className='m-t-10' gutter={[24, 12]}>
                            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                                <Card className='bg-light-grey small-rounded' bodyStyle={{ padding: 10 }}>
                                    <Row>
                                        <Col span={16}>
                                            <Space direction='vertical' size={0}>
                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>Account Holder Name</Typography.Text>
                                                <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountHolderName}</Typography.Text>
                                            </Space>
                                        </Col>
                                        <Col span={8}>
                                            <Space direction='vertical' size={0}>
                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>{volopaAccountSortCodeLabel}</Typography.Text>
                                                <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSortCode}</Typography.Text>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row className='m-t-10'>
                                        <Col span={16}>
                                            {fundingLimitReached ?
                                                <Space direction="vertical" size={0}>
                                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                                    <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                                </Space> :
                                                <Space direction="vertical" size={0}>
                                                    <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                                    <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                                </Space>
                                            }
                                        </Col>
                                        <Col span={8}>
                                            {!fundingLimitReached ?
                                                <Space direction='vertical' size={0}>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                                    <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountCountry}</Typography.Text>
                                                </Space> :
                                                <Space direction='vertical' size={0}>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Mandatory Funding Reference</Typography.Text>
                                                    <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountPaymentRef}</Typography.Text>
                                                </Space>
                                            }
                                        </Col>
                                    </Row>
                                    {fundingLimitReached &&
                                        <Row className='m-t-10'>
                                            <Col span={16}>
                                                <Space direction='vertical' size={0}>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                                    <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountIban}</Typography.Text>
                                                </Space>
                                            </Col>
                                            <Col span={8}>
                                                <Space direction='vertical' size={0}>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                                    <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSwift}</Typography.Text>
                                                </Space>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='m-t-10'>
                                        <Col span={24}>
                                            <Space size={0} direction='vertical'>
                                                <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Name</Typography.Text>
                                                <Typography.Text className="dark-green fs-18px muli light">{volopaAccountName}</Typography.Text>
                                            </Space>
                                        </Col>
                                        <Col span={24}>
                                            <Space direction='vertical' size={0}>
                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Address</Typography.Text>
                                                <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountAddress}</Typography.Text>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row className='m-t-10 m-b-10'>
                                        <Col span={16}>
                                            <Space direction='vertical' size={0}>
                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>Funding Amount</Typography.Text>
                                                <Space size='large' wrap>
                                                    <Space>
                                                        <Image src={FLAGS[currency]} alt={currency} width={24} preview={false} />
                                                        <Typography.Text className='muli fs-18px light dark-green'>
                                                            {currency}
                                                        </Typography.Text>
                                                    </Space>
                                                    <Typography.Text className='muli fs-18px light dark-green'>
                                                        {accountingFormat((parseFloat(youSend)))}
                                                    </Typography.Text>
                                                </Space>
                                            </Space>
                                        </Col>
                                        <Col span={8}>
                                            <Row>
                                                <Col>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Payment Reference</Typography.Text>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Typography.Text className='muli fs-18px light dark-green'>{paymentReference}</Typography.Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {fundingLimitReached && <Typography.Text className='muli semi-bold dark-green fs-12px'>*Please note that the Mandatory Funding Reference is required to ensure we identify your funds efficiently.</Typography.Text>}
                                    {config.is_eea === 1 && <Row className='m-t-10 m-b-10'><Typography.Text className='muli semi-bold dark-green fs-12px'>Please ensure that you transfer the funds from the bank account which you have verified. Please note that sending the funds from a different bank account may result in the payment being declined.</Typography.Text></Row>}
                                </Card>
                            </Col>
                            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                                <Row>
                                    <Col span={12}>
                                        <Typography.Text className='dark-green medium fs-18px'>Selected Recipient</Typography.Text>
                                    </Col>
                                    <Col span={12}>
                                        <Typography.Text className='dark-green muli semi-bold fs-18px'>{recipient && (recipient.name !== "undefined undefined" ? recipient.name : recipient.beneficiary_company_name)}</Typography.Text>
                                    </Col>
                                </Row>
                                <Row className='m-t-20'>
                                    <Col span={24}>
                                        <Typography.Text className='dark-green medium fs-18px'>Payment Details </Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Typography.Text className='dark-green muli semi-bold fs-18px'>Expected Delivery</Typography.Text>
                                    </Col>
                                    <Col span={12}>
                                        <Typography.Text className='dark-green muli light fs-18px'>{firstDate}</Typography.Text>
                                    </Col>
                                </Row>
                                <Row className='m-t-20' align='middle'>
                                    <Col span={12}>
                                        <Typography.Text className='dark-green muli semi-bold fs-18px'>Exchange Rate <Tooltip title="Exchange Rate has been rounded to 6 decimal points."><FaInfoCircle /></Tooltip></Typography.Text>
                                    </Col>
                                    <Col span={10}>
                                        <Space direction='vertical' size={0}>
                                            <Typography.Text className='dark-green muli semi-bold fs-18px'>1 {currency} = {displayRate} {recipient ? recipient.currency : ''}</Typography.Text>
                                            <Typography.Text className='dark-green muli semi-bold fs-18px'>1 {recipient ? recipient.currency : ''} = {displayRateInversed} {currency}</Typography.Text>
                                        </Space>
                                    </Col>
                                    <Col span={2}>
                                        <Spin spinning={handleOnFinishLoading} size={'small'} ></Spin>
                                        <Space>
                                            <Progress
                                                type="circle"
                                                percent={(seconds / (+config?.card_expires_in + 0.01)) * 100}
                                                width={40}
                                                format={() => `${seconds}s`}
                                            />
                                            {/* <Tooltip title="FX rate will renew every 30 seconds."><FaInfoCircle className='dark-green' /></Tooltip> */}
                                        </Space>
                                    </Col>
                                </Row>
                                <Row className='m-t-20'>
                                    <Col span={10}>
                                        <Typography.Text className='muli extra-bold dark-green fs-18px'>Recipient Receives</Typography.Text>
                                    </Col>
                                    <Col span={8}>
                                        <Typography.Text className='muli extra-bold dark-green fs-18px'>{recipientReceives ? accountingFormat(parseFloat(recipientReceives)) : "-"}</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text className='muli extra-bold dark-green fs-18px'>{recipient ? recipient.currency : ''}</Typography.Text>
                                    </Col>
                                </Row>
                                <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                                <Row>
                                    <Col span={10}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>We Convert</Typography.Text>
                                    </Col>
                                    <Col span={8}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>{accountingFormat(weConvert) || "-"}</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>{currency}</Typography.Text>
                                    </Col>
                                </Row>
                                <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                                <Row>
                                    <Col span={10}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>Payment Fees</Typography.Text>
                                    </Col>
                                    <Col span={8}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>{fee}</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>{currency}</Typography.Text>
                                    </Col>
                                </Row>
                                <Row className='m-t-20'>
                                    <Col span={10}>
                                        <Typography.Text type='danger' className='muli semi-bold fs-18px'>You Send</Typography.Text>
                                    </Col>
                                    <Col span={8}>
                                        <Typography.Text type='danger' className='muli semi-bold fs-18px'>{accountingFormat(parseFloat(youSend)) || '-'}</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text type='danger' className='muli semi-bold fs-18px'>{currency}</Typography.Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='m-t-20' justify='center'>
                            <Col>
                                {modalType === 'fundingConfirmation' && (
                                    <Space size={60}>
                                        {!mapId &&
                                            <Button
                                                type='primary'
                                                danger={!approvalRequired}
                                                onClick={() => setState({
                                                    modalOpen: false,
                                                    modalType: 'fundingConfirmation',
                                                })}
                                                disabled={acceptQuoteLoading}
                                            >
                                                {approvalRequired ? 'Return to Payment Details' : 'Cancel'}
                                            </Button>
                                        }
                                        {mapId &&
                                            <Button
                                                type='primary'
                                                onClick={() => navigate(URLS.DraftPayments)}
                                                disabled={acceptQuoteLoading}
                                            >
                                                Return to Draft Payments
                                            </Button>
                                        }
                                        {mapId &&
                                            <Button
                                                type='primary'
                                                danger
                                                onClick={handleDeleteDraft}
                                                loading={deletingDraft}
                                                disabled={acceptQuoteLoading}
                                            >
                                                Delete Draft
                                            </Button>
                                        }
                                        <Button type='primary' onClick={() => acceptQuote()} loading={acceptQuoteLoading} disabled={(!!mapId && !isApprover?.approver) || handleOnFinishLoading}>
                                            {approvalRequired ?
                                                'Submit for Approval' :
                                                (scheduled ?
                                                    'Schedule Payment' :
                                                    'Pay Recipient'
                                                )
                                            }
                                        </Button>
                                    </Space>
                                )}
                                {modalType === 'pendingFunds' && !approvalRequired && (
                                    <Space size={60}>
                                        {fundingMethod === OPT_FUNDING_METHOD.EASY_TRANSFER && <Button type='primary' onClick={handleEasyTransfer} loading={acceptQuoteLoading}>Fund via Easy Transfer (Open Banking)</Button>}
                                        <Button type='primary' ghost onClick={() => viewPayment()}>View Payment</Button>
                                        <Button type='primary' ghost onClick={resetData}>New Payment</Button>
                                        <Button type='primary' onClick={() => goToDashBoard()}>Dashboard</Button>
                                    </Space>
                                )}
                                {modalType === 'pendingFunds' && !!approvalRequired && (
                                    <Space size={60}>
                                        <Button type='primary' onClick={() => goToDashBoard()}>Return to Dashboard</Button>
                                        <Button type='primary' ghost disabled>Submit for Approval</Button>
                                    </Space>
                                )}
                            </Col>
                        </Row>
                        {(!!approvalRequired || !!mapId || !!scheduled) &&
                            <Row justify='center' className='m-t-20'>
                                <Col>
                                    {!!approvalRequired && !scheduled &&
                                        ((modalType === 'pendingFunds' && !mapId) || (modalType === 'fundingConfirmation' && mapId)) &&
                                        <Typography.Text className='fs-18px medium center-align-text' type='warning'>
                                            Pending Approval
                                        </Typography.Text>
                                    }
                                    {modalType === 'fundingConfirmation' && !mapId && !scheduled &&
                                        <Typography.Text className='fs-18px medium center-align-text' type='danger'>
                                            The FX contract for Payments that are pending approval is not booked.
                                            The live FX contract will be displayed to the Approver at the point of approval
                                        </Typography.Text>
                                    }
                                    {!!scheduled &&
                                        <Typography.Text className='fs-18px medium center-align-text' type='danger'>
                                            NOTE:  Exchange Rate & Amounts are indicative only.
                                            Exchange Rates & Amounts are confirmed in time for the scheduled Expected Delivery date.
                                        </Typography.Text>
                                    }
                                </Col>
                            </Row>
                        }
                    </Modal>
                    <Row>
                        <Col span={24}>
                            <PageDescription title='Create a Payment' text='- Create a Payment to a Recipient' />
                        </Col>
                    </Row>
                    {recipient === undefined ? (
                        <Row className='m-t-10'>
                            <Col>
                                <Tooltip
                                    title="This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed."
                                    {...(!accountStatus?.suspended_accounts?.payments && { open: false })}
                                >
                                    <Select
                                        style={{ minWidth: '250px' }}
                                        dropdownStyle={{ paddingTop: 0 }}
                                        placeholder='Search Recipient'
                                        showSearch
                                        filterOption={filterSelect}
                                        onChange={handleSelect}
                                        disabled={!!accountStatus?.suspended_accounts?.payments}
                                    >
                                        <Select.OptGroup
                                            style={{ padding: 0, margin: 0, backgroundColor: 'red' }}
                                            label={
                                                <Row style={{ marginLeft: -12, marginRight: -12, marginTop: -5, marginBottom: -5 }} className='bg-dark-green p-t-5 p-b-5' align='middle'>
                                                    <Col span={24} className='p-l-10 p-r-10'>
                                                        <Row justify='space-between'>
                                                            <Col>
                                                                <Typography.Text className='muli semi-bold fs-24px white'>
                                                                    Common
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col>
                                                                <Button type='primary' className='bg-light-green' size='small' onClick={() => navigate(URLS.AddRecipient)}>Add Recipient</Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            }
                                            key='frequent_recipients'
                                        >
                                            {!!recipients?.frequentRecipients && recipients?.frequentRecipients?.map((recipient) =>
                                                <Select.Option key={recipient?.key} value={recipient?.id}>{recipient?.name}</Select.Option>
                                            )}
                                        </Select.OptGroup>

                                        {!!recipients?.recipientsGrouped && recipients?.recipientsGrouped?.map(group =>
                                            <Select.OptGroup key={group?.letter} label={group?.letter}>
                                                {group?.recipients?.map(recipient =>
                                                    <Select.Option key={recipient?.key} value={recipient?.id}>{recipient?.name}</Select.Option>
                                                )}
                                            </Select.OptGroup>
                                        )}

                                    </Select>
                                </Tooltip>
                            </Col>
                        </Row>
                    ) : (
                        <Form
                            layout='vertical'
                            onFinish={handleOnFinish}
                            initialValues={{
                                fundingMethod: OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS,
                                crossBorderPaymentPurposeCode: undefined,
                                // crossBorderPaymentPurposeCode: TCC_CROSS_BORDER_PAYMENT_PURPOSE_CODES?.[`${recipient?.bank_country}_${recipient?.currency}`]?.[0]?.code || undefined,
                            }}
                            form={form}
                        >
                            <FullScreenModal onCancel={() => setState({ viewDetailsModalOpen: false })} open={viewDetailsModalOpen}>
                                <RecipientDetails recipientId={recipient?.id} />
                            </FullScreenModal>
                            <Row className='m-t-20' gutter={[24, 24]}>
                                <Col xxl={8} xl={10} lg={10} md={12} sm={20} xs={24}>
                                    <Row>
                                        <Col span={24}>
                                            <Card hoverable className='b-g' bodyStyle={{ padding: '20px' }}>
                                                <Row justify='space-between'>
                                                    <Col>
                                                        <Typography.Text className='fs-18px medium dark-green'>Recipient Details</Typography.Text>
                                                    </Col>
                                                    <Col><AiOutlineClose size={24} onClick={() => resetData()} /></Col>
                                                </Row>
                                                <Row gutter={24} className='m-t-10'>
                                                    <Col>
                                                        <Avatar size={120} className='bg-white b-g p-t-2' icon={<FaUser className='grey' />} />
                                                    </Col>
                                                    <Col flex='auto'>
                                                        <Space direction='vertical' size={0}>
                                                            <Typography.Text className='muli semi-bold fs-24px dark-green'>{recipient?.name !== "undefined undefined" ? recipient?.name : recipient?.beneficiary_company_name}</Typography.Text>
                                                            <Typography.Text className='muli light fs-18px dark-green'>{(recipient?.beneficiary_first_name && recipient?.beneficiary_last_name) && (recipient?.beneficiary_first_name + ". " + (recipient?.beneficiary_last_name))}</Typography.Text>
                                                            <Typography.Text className='muli light fs-18px dark-green'>{recipient?.currency}</Typography.Text>
                                                            <Typography.Text className='muli light fs-18px dark-green'>{recipient?.bank_name}</Typography.Text>
                                                            <Typography.Text className='muli light fs-18px dark-green'>{recipient?.iban ? recipient?.iban && "**** ***** " + recipient?.iban?.substr(-4) : (recipient?.account_number && "**** ***** " + recipient?.account_number.substr(-4))}</Typography.Text>
                                                        </Space>
                                                        <Row justify='end'>
                                                            <Col>
                                                                <Typography.Text onClick={() => goToSpecificRecipient()} className='underline muli semi-bold fs-18px pointer'>View Details</Typography.Text>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className='m-t-20'>
                                        <Col span={24}>
                                            <Card hoverable className='b-g' bodyStyle={{ padding: '20px' }}>
                                                <Space>
                                                    <Typography.Text className='fs-18px medium dark-green'>Enter Payment Details</Typography.Text>
                                                    <Tooltip title="Please provide your payment details."><FaInfoCircle className='dark-green' /></Tooltip>
                                                </Space>
                                                <Row gutter={8}>
                                                    <Col flex='100px'>
                                                        <Input readOnly value={recipient?.currency} prefix={
                                                            FLAGS[recipient?.currency] &&
                                                            <Image
                                                                src={FLAGS[recipient?.currency]}
                                                                preview={false}
                                                                width={20}
                                                                alt={recipient?.currency}
                                                            />
                                                        } suffix={<FaLock />} />
                                                    </Col>
                                                    <Col flex='auto'>
                                                        <Form.Item
                                                            name='recipientReceives'
                                                            style={{ marginBottom: 5 }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                placeholder='Recipient Receives'
                                                                className='full-percent-width'
                                                                onKeyDown={() => updateSetAmount("buy")}
                                                                onKeyUp={updateRecipientRecieves}
                                                                min={0}
                                                                formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                                parser={(val) => numberFormat(val)}
                                                            />
                                                        </Form.Item>
                                                        <Typography.Text style={{ visibility: (currencyLimitUKOrEEA || totalDailyLimitReached) ? 'visible' : 'hidden' }} className='fs-14px ant-typography-danger'>
                                                            {totalDailyLimitReached ? `Maximum Total Daily Limit Reached for ${recipient?.currency ?? ''}` :
                                                                `Maximum limit for ${recipient?.currency ?? ''} is ${accountingFormat(selectedCurrency?.upper_limit)}`
                                                            }
                                                        </Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row gutter={8}>
                                                    <Col flex='100px'>
                                                        <Form.Item name='currency'>
                                                            <Select className='full-percent-width'>
                                                                {commonCurrencies && commonCurrencies.length > 0 && (
                                                                    <Select.OptGroup label="Common">
                                                                        {commonCurrencies.map((val, key) => (
                                                                            <Select.Option value={val.currency} key={key} className='p-l-10'>
                                                                                <Space size={4} align="center">
                                                                                    {val.flag && (
                                                                                        <Image
                                                                                            src={val.flag}
                                                                                            preview={false}
                                                                                            width={20}
                                                                                            alt={val.currency}
                                                                                        />
                                                                                    )}
                                                                                    {val.currency}
                                                                                </Space>
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select.OptGroup>
                                                                )}
                                                                {currencies && currencies.length > 0 && (
                                                                    <Select.OptGroup label="Other">
                                                                        {currencies.map((val, key) => (
                                                                            <Select.Option
                                                                                key={
                                                                                    key +
                                                                                    (commonCurrencies
                                                                                        ? commonCurrencies.length
                                                                                        : 0)
                                                                                }
                                                                                value={val.currency}
                                                                                className='p-l-10'
                                                                            >
                                                                                <Space size={4} align="center">
                                                                                    {val.flag && (
                                                                                        <Image
                                                                                            src={val.flag}
                                                                                            preview={false}
                                                                                            width={20}
                                                                                            alt={val.currency}
                                                                                        />
                                                                                    )}
                                                                                    {val.currency}
                                                                                </Space>
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select.OptGroup>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col flex='auto'>
                                                        <Form.Item
                                                            name='youSend'
                                                            style={{ marginBottom: 5 }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                placeholder='You Send'
                                                                className='full-percent-width'
                                                                onKeyDown={() => updateSetAmount("sell")}
                                                                onKeyUp={updateYouSend}
                                                                min={0}
                                                                formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                                parser={(val) => numberFormat(val)}
                                                            />

                                                        </Form.Item>
                                                        {validatorState.current === true && (<Typography.Text className='fs-14px ant-typography-danger'>Minimum amount to send is {currency ?? ''} 10</Typography.Text>)}
                                                    </Col>
                                                </Row>

                                                <Row justify='space-between'>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <Space>
                                                                    <Typography.Text className='fs-18px medium dark-green'>Funding Method</Typography.Text>
                                                                    <Tooltip
                                                                        title={
                                                                            <Space direction='vertical'>
                                                                                <>
                                                                                    Push Funds: Once you submit the payment we will supply the Account details to fund the payment.
                                                                                </>
                                                                                <>
                                                                                    Easy Transfer: We will redirect you to your online banking to authorise sending the funds via Open Banking
                                                                                </>
                                                                                <>
                                                                                    Volopa Collection Account: Once you submit the payment we will supply the Account details to fund the payment.
                                                                                </>
                                                                            </Space>
                                                                        }
                                                                    >
                                                                        <FaInfoCircle className='dark-green' />
                                                                    </Tooltip>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Item name='fundingMethod'>
                                                                    <Select style={{ minWidth: '200px' }}>
                                                                        <Select.Option value={OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS}>
                                                                            <Tooltip placement="right" title="Once you submit the payment, we will supply the Account details to fund the payment.">
                                                                                Push Funds
                                                                            </Tooltip>
                                                                        </Select.Option>
                                                                        <Select.Option value={OPT_FUNDING_METHOD.EASY_TRANSFER} disabled={disableEasyTransfer}>
                                                                            <Tooltip placement='right' title="We will redirect you to your banking platform to authorise the payment (Only available when funding in GBP or EUR)">
                                                                                Easy Transfer
                                                                            </Tooltip>
                                                                        </Select.Option>
                                                                        <Select.Option
                                                                            value={OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT}
                                                                            disabled={!config?.service_statuses?.payments_status || !config?.service_statuses?.collections_status}
                                                                        >
                                                                            <Tooltip placement="right" title="Once you submit the payment, we will supply the Account details to fund the payment.">
                                                                                Volopa Collection Account
                                                                            </Tooltip>
                                                                        </Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Space>
                                                                    <Typography.Text className='fs-18px medium dark-green'>Settlement Method</Typography.Text>
                                                                    <Tooltip title="Priority Payments: Settled via SWIFT network. Recalls and payment tracing available. Regular Payments: Local payment method, cannot be recalled and no tracing available.
Please note that Regular Payments may not be available due to the following reasons: (1) Local payment options are not available to this payment corridor (2) You have not added the Regular Payment details for the selected recipient."><FaInfoCircle className='dark-green' /> </Tooltip>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Space>
                                                                    <Button type='primary'  {...(disabledMethod === 'regular' && { disabled: true })}  {...(settlementMethod === 'priority' && { ghost: true })} onClick={(e) => updateSettlementMethod(e)}>
                                                                        Regular
                                                                    </Button>
                                                                    <Button
                                                                        type='primary'
                                                                        {...(disabledMethod === 'priority' && { disabled: true })}
                                                                        {...(settlementMethod === 'regular' && { ghost: true })}
                                                                        style={settlementMethod === 'priority' ? { color: 'white' } : {}}
                                                                        onFocus={(e) => {
                                                                            if (settlementMethod === 'priority') {
                                                                                e.target.style.color = 'white';
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (settlementMethod === 'priority') {
                                                                                e.target.style.color = 'white';
                                                                            } else {
                                                                                e.target.style.color = ''; // Reverts to default color
                                                                            }
                                                                        }}
                                                                        onClick={(e) => updateSettlementMethod(e)}
                                                                        className='warning'
                                                                    >
                                                                        Priority
                                                                    </Button>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className='m-t-20'>
                                                        <Space>
                                                            <Progress
                                                                type="circle"
                                                                percent={(seconds / (+config.card_expires_in + 0.01)) * 100}
                                                                width={40}
                                                                format={() => `${seconds}s`}
                                                            />
                                                            <Tooltip title="FX rate will renew every 30 seconds."><FaInfoCircle className='dark-green' /></Tooltip>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={16} xl={14} lg={14} md={16} sm={20} xs={24}>
                                    <Row align='bottom' gutter={[24, 24]}>
                                        <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Card hoverable className='b-g' bodyStyle={{ padding: '20px' }}>
                                                        <Row>
                                                            <Col>
                                                                <Space>
                                                                    <Typography.Text className='fs-18px medium dark-green'>Recipient Email Address</Typography.Text>
                                                                    <Tooltip title="Once the payment has been sent, we will send an email confirmation to your recipient that the payment is on it's way."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name='email'
                                                                >
                                                                    <Input placeholder='Enter email' readOnly={true}  {...(recipient?.email)} value={recipient?.email} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name='ccEmail'
                                                                >
                                                                    <Input placeholder='Enter CC email' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {(showPaymentDetails) && (
                                                <Row className='m-t-20'>
                                                    <Col span={24}>
                                                        <Card hoverable style={{ minHeight: '360px' }} className='b-g default-cursor' bodyStyle={{ padding: '20px' }}>
                                                            <Row>
                                                                <Col>
                                                                    <Space>
                                                                        <Typography.Text className='fs-18px medium dark-green'>Payment Details</Typography.Text>
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                            <Row className='m-t-20' gutter={10} justify='space-between'>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <Typography.Text className='muli semi-bold fs-18px dark-green'>Expected Delivery</Typography.Text>
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Item name='scheduleDate'>
                                                                                <DatePicker
                                                                                    bordered={false}
                                                                                    style={{ width: '24px', padding: '0px' }}
                                                                                    suffixIcon={<FaRegCalendarAlt size={18} className='dark-green' />}
                                                                                    inputReadOnly
                                                                                    disabledDate={handleDisabledDates}
                                                                                    showToday={false}
                                                                                    placeholder=''
                                                                                    disabled={disableScheduling}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text className='muli light fs-18px dark-green'>{scheduleDate?.format('DD-MM-YYYY') || firstDate}</Typography.Text>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={12} justify='space-between'>
                                                                <Col>
                                                                    <Space>
                                                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>Exchange Rate</Typography.Text>
                                                                        <Tooltip title="Exchange Rate has been rounded to 6 decimal points."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                    </Space>
                                                                </Col>
                                                                <Col>
                                                                    <Space direction='vertical' size={0}>
                                                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>1 {currency} = {displayRate} {recipient ? recipient.currency : ''}</Typography.Text>
                                                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>1 {recipient ? recipient.currency : ''} = {displayRateInversed} {currency}</Typography.Text>
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                            <Row className='m-t-20' gutter={12} justify='space-between'>
                                                                <Col flex='200px'>
                                                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>Recipient Receives</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>{recipientReceives ? accountingFormat(parseFloat(recipientReceives)) : "-"}</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>{recipient ? recipient.currency : ''}</Typography.Text>
                                                                </Col>
                                                            </Row>
                                                            <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                                                            <Row gutter={12} justify='space-between'>
                                                                <Col flex='200px'>
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>We Convert</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{accountingFormat(weConvert) || "-"}</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{currency}</Typography.Text>
                                                                </Col>
                                                            </Row>
                                                            <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                                                            <Row gutter={12} justify='space-between'>
                                                                <Col flex='200px'>
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>Payment Fees</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{fee}</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{currency}</Typography.Text>
                                                                </Col>
                                                            </Row>
                                                            <Row className='m-t-20' gutter={12} justify='space-between'>
                                                                <Col flex='200px'>
                                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>You Send</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{accountingFormat(parseFloat(youSend)) || '-'}</Typography.Text>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{currency}</Typography.Text>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                        {(showPaymentDetails) && (
                                            <Col xxl={12} xl={18} lg={18} md={20} sm={20} xs={24}>
                                                <Row>
                                                    <Col>
                                                        <Space>
                                                            <Typography.Text className='fs-18px medium dark-green'>Reason For Payment</Typography.Text>
                                                            <Tooltip title="The Purpose of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Item
                                                            hidden={!checkIfCrossBorderPurposeApplicable}
                                                            name='crossBorderPaymentPurposeCode'
                                                            rules={[
                                                                {
                                                                    required: !!checkIfCrossBorderPurposeApplicable,
                                                                    message: 'Please select a payment purpose'
                                                                }
                                                            ]}
                                                        >
                                                            <Select style={{ minWidth: '250px' }} className='b-g'>
                                                                {crossBorderPaymentPurposeCode?.map((pcode) => (
                                                                    <Select.Option key={pcode.code} value={pcode.code}>{pcode.label}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            hidden={!!checkIfCrossBorderPurposeApplicable}
                                                            name='paymentPurpose'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select a payment reason'
                                                                }
                                                            ]}
                                                        >
                                                            <Select style={{ minWidth: '250px' }} className='b-g'>
                                                                {purposeOptionsStructure}
                                                            </Select>
                                                        </Form.Item>
                                                        {paymentPurpose === "Other" &&
                                                            <Form.Item
                                                                name='paymentPurposeOther'
                                                            >
                                                                <Input placeholder='Other Reason' />
                                                            </Form.Item>
                                                        }
                                                    </Col>
                                                </Row>
                                                {showInvoiceAndDateFields && <Col xxl={12} xl={18} lg={18} md={20} sm={20} xs={24}>
                                                    <Row>
                                                        <Col>
                                                            <Space>
                                                                <Typography.Text className='fs-18px medium dark-green'>Invoice Number</Typography.Text>
                                                                <Tooltip title="The Invoice Number of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Item
                                                                name='invoiceNumber'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please Enter the invoice number'
                                                                    }
                                                                ]}
                                                            >
                                                                <Input style={{ minWidth: '250px' }} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Space>
                                                                <Typography.Text className='fs-18px medium dark-green'>Invoice Date</Typography.Text>
                                                                <Tooltip title="The Invoice Date of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Item
                                                                name='invoiceDate'
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please Enter the invoice date'
                                                                    }
                                                                ]}
                                                            >
                                                                <Input type='date' style={{ minWidth: '250px' }} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>}
                                                <Row className='m-t-20'>
                                                    <Col span={24}>
                                                        <Card hoverable className='b-g' bodyStyle={{ padding: '20px' }}>
                                                            <Row>
                                                                <Col>
                                                                    <Space>
                                                                        <Typography.Text className='fs-18px medium dark-green'>Payment Reference</Typography.Text>
                                                                        <Tooltip title="Add payment notes to your recipient in this field."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Form.Item name='paymentReference'>
                                                                        <Input />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row >

                            {(
                                showPaymentDetails && paymentPurpose
                                && (!!checkIfCrossBorderPurposeApplicable ? !!form?.getFieldValue('crossBorderPaymentPurposeCode') : true)
                                && (showInvoiceAndDateFields ? !!form?.getFieldValue('invoiceNumber') : true)
                                && (showInvoiceAndDateFields ? !!form?.getFieldValue('invoiceDate') : true)
                                && paymentReference && validatorState.current === false
                            ) && (
                                    <Row justify='end' className='m-t-20'>
                                        <Col>
                                            <Button type='primary' htmlType='submit' loading={handleOnFinishLoading} disabled={handleOnFinishLoading}>Proceed to Pay</Button>
                                        </Col>
                                    </Row>
                                )
                            }
                        </Form >
                    )}
                </>
            }
        </Spin >
    );
}

export default NewPayment;
