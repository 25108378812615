/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Spin, Tooltip, Typography, notification } from "antd";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageDescription from "App/Components/PageDescription";
import useFundWalletState from "App/Pages/Wallet/FundWallet/store";
import URLS from "Routes/constants";
import { FLAGS } from "Constants/Images";
import { useApiRequest } from "Hooks/API";
import CompanyWalletBalance from "App/Components/CompanyWalletBalance";
import { accountingFormat, numberFormat } from "Utils";
import { APIROUTES } from "Constants/ApiRoutes";
import YapilyCallbackComponent from "./components/YapilyCallbackComponent";
import { useLoginStore } from "App/Pages/Login/store";
import { OPT_FUNDING_METHOD } from "Constants/Opts";
import { useCheckAccountSuspensionQuery } from "Hooks/Queries";

function FundWallet() {
    const showModal = useFundWalletState(state => state.showModal);
    const setState = useFundWalletState(state => state.setState);
    const contentIndex = useFundWalletState(state => state.contentIndex);
    const loadingFund = useFundWalletState((state) => state.loadingFund);
    const loadingPayment = useFundWalletState((state) => state.loadingPayment);
    const loadingPaymentRequest = useFundWalletState((state) => state.loadingPaymentRequest);
    const transactionComplete = useFundWalletState((state) => state.transactionComplete);
    const transactionStatus = useFundWalletState((state) => state.transactionStatus);
    const message = useFundWalletState(state => state.message);
    const currency = useFundWalletState(state => state.currency);
    const method = useFundWalletState(state => state.method);
    const description = useFundWalletState(state => state.description);
    const disableEasyTransfer = useFundWalletState(state => state.disableEasyTransfer);
    const volopaAccountHolderName = useFundWalletState(state => state.volopaAccountHolderName);
    const volopaAccountSortCode = useFundWalletState(state => state.volopaAccountSortCode);
    const volopaAccountNumber = useFundWalletState(state => state.volopaAccountNumber);
    const volopaAccountCountry = useFundWalletState(state => state.volopaAccountCountry);
    const volopaAccountAddress = useFundWalletState(state => state.volopaAccountAddress);
    const volopaAccountName = useFundWalletState(state => state.volopaAccountName);
    const volopaAccountPaymentRef = useFundWalletState(state => state.volopaAccountPaymentRef);
    const volopaAccountIban = useFundWalletState(state => state.volopaAccountIban);
    const volopaAccountSwift = useFundWalletState(state => state.volopaAccountSwift);
    const volopaAccountNumberType = useFundWalletState(state => state.volopaAccountNumberType);
    const volopaAccountSortCodeLabel = useFundWalletState(state => state.volopaAccountSortCodeLabel);
    const volopaAccountDetails = useFundWalletState(state => state.volopaAccountDetails);
    const fundingLimitReached = useFundWalletState(state => state.fundingLimitReached);
    const fundingAccountExists = useFundWalletState(state => state.fundingAccountExists);
    const resetState = useFundWalletState(state => state.resetState);
    const navigate = useNavigate();
    const apiRequest = useApiRequest();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const [form] = Form.useForm();
    const currencySelected = Form.useWatch('currency', form);
    const amountEntered = Form.useWatch('amount', form);
    const [modalForm] = Form.useForm();
    const config = useLoginStore(s => s.config);
    const { data: accountStatus, isFetching: accountStatusFetching } = useCheckAccountSuspensionQuery();

    const getPaymentDetailRetry = async () => {
        try {
            setState({ loadingPaymentRequest: true });
            const timeout = setTimeout(() => {
                clearInterval(interval);
                setState({ loadingPaymentRequest: false, transactionComplete: true, transactionStatus: 'FAILED' });
            }, 120000);
            const interval = setInterval(async () => {
                const response = await apiRequest(`${APIROUTES.post.get_payment_detail}`, 'POST', { paymentRequestId: query.get('paymentRequestId') });
                if (response && response?.success && (response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status === 'COMPLETED' || response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status === 'PENDING')) {
                    setState({ loadingPaymentRequest: false, transactionComplete: true, transactionStatus: response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status });
                    clearInterval(interval);
                    clearTimeout(timeout)
                    return;
                }
            }, 5000);
        } catch (error) {
            getPaymentDetailRetry()
        }
    };

    const getPaymentDetailsYapily = async () => {
        try {
            setState({ loadingPaymentRequest: true });
            const response = await apiRequest(`${APIROUTES.post.get_payment_detail}`, 'POST', { paymentRequestId: query.get('paymentRequestId') });
            if (response && response?.success) {
                setState({ transactionComplete: true, transactionStatus: response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status, loadingPaymentRequest: false });
                return;
            }
            getPaymentDetailRetry()
        } catch {
            getPaymentDetailRetry()
        }
    }

    const toggleModal = () => {
        const method = form.getFieldValue('method') === OPT_FUNDING_METHOD.EASY_TRANSFER
        setState({ showModal: !showModal, contentIndex: method ? 1 : 0, message: method ? 'Fund via Easy Transfer (Open Banking)' : message, loadingFund: false, currency: form.getFieldValue('currency'), description: form.getFieldValue('description') });
        if (showModal) {
            form.resetFields();
            modalForm?.resetFields();
        }
    };
    const toggleContentIndex = () => setState({ contentIndex: contentIndex ? 0 : 1 });


    useEffect(() => {
        const amountLimit = amountEntered ? amountEntered : 0;
        const method = form.getFieldValue('method');
        if (method !== OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT) {
            if (['GBP', 'EUR'].includes(currencySelected) && (amountLimit < 75000) && config?.easy_transfer_allowed) {
                form.setFieldValue('method', OPT_FUNDING_METHOD.EASY_TRANSFER)
                setState({ disableEasyTransfer: false })
            } else {
                form.setFieldValue('method', OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS)
                setState({ disableEasyTransfer: true })
            }
        }
    }, [currencySelected])

    useEffect(() => {
        const amountLimit = amountEntered ? amountEntered : 0;
        const method = form.getFieldValue('method');
        if (amountLimit >= 75000 && (method === OPT_FUNDING_METHOD.EASY_TRANSFER)) {
            form.setFieldValue('method', OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS)
            setState({ disableEasyTransfer: true })
        } else if (['GBP', 'EUR'].includes(currencySelected) && config?.easy_transfer_allowed) {
            setState({ disableEasyTransfer: false })
        }
    }, [amountEntered])

    useEffect(() => {
        if (currency && method) {
            form.setFieldsValue({ currency, method })
        }
        if (query.get('paymentRequestId')) {
            getPaymentDetailsYapily();
        }
        return (() => {
            resetState();
            form?.resetFields();
            modalForm?.resetFields();
        })
    }, []);

    useEffect(() => {
        if (currency) {
            getPaymentDetails();
        }
    }, [currency]);

    useEffect(() => {
        if (
            !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                !!accountStatus?.suspended_accounts?.cards
        ) {
            notification.destroy();
            notification.error({
                description: 'This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed.',
                placement: 'top'
            })
        }
    }, [accountStatus]);

    const getPaymentDetails = async () => {
        let response = await apiRequest("tcc/fund/account?currency=" + currency);
        // response.data.result.funding_accounts = undefined;
        if (response && response.data && (response?.data?.result?.funding_accounts?.find(account => account?.payment_type === "regular") || response?.data?.result?.settlement_accounts?.[0])) {
            setState({ volopaAccountDetails: response.data.result, fundingAccountExists: true });
            const over = response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") ? false : true
            updateFundingDetails(null, over, response.data.result)
        } else {
            notification.destroy();
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading the Volopa funding details for this currency', placement: 'top' });
            setState({ fundingAccountExists: false })
        }
    }

    const updateFundingDetails = (e, over, accountDetails) => {
        let sortCodeLabel = 'Sort Code';
        if (over === false && accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")) {
            let routingCodeType = accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            if (accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular"))
                setState({
                    volopaAccountHolderName: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name,
                    volopaAccountSortCode: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code,
                    volopaAccountNumber: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number,
                    volopaAccountCountry: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country,
                    volopaAccountAddress: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address,
                    volopaAccountName: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name,
                    volopaAccountPaymentRef: accountDetails?.short_reference,
                    volopaAccountIban: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.iban,
                    volopaAccountSwift: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.bic_swift,
                    volopaAccountNumberType: accountDetails?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type,
                    volopaAccountSortCodeLabel: sortCodeLabel,
                    fundingLimitReached: false
                });
        } else {
            setState({
                volopaAccountHolderName: accountDetails?.settlement_accounts[0].bank_account_holder_name,
                volopaAccountSortCode: accountDetails?.settlement_accounts[0].routing_code_value_1,
                volopaAccountNumber: accountDetails?.settlement_accounts[0].account_number,
                volopaAccountCountry: accountDetails?.settlement_accounts[0].bank_country,
                volopaAccountAddress: accountDetails?.settlement_accounts[0].bank_address,
                volopaAccountName: accountDetails?.settlement_accounts[0].bank_name,
                volopaAccountPaymentRef: accountDetails?.short_reference,
                volopaAccountIban: accountDetails?.settlement_accounts[0].iban,
                volopaAccountSwift: accountDetails?.settlement_accounts[0].bic_swift,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: true
            });
        }
    }

    const submitFundingRequest = async () => {
        setState({ loadingFund: true });
        const data = {
            currency: form.getFieldValue("currency"),
            amount: form.getFieldValue("amount"),
            description: form.getFieldValue("description"),
            fund_method_id: form.getFieldValue('method')
        };
        const fundResponse = await apiRequest("tcc/company/wallet", "POST", data);
        if (fundResponse.response && fundResponse.response.data.error) {
            if (fundResponse.status === 400) {
                setState({ message: fundResponse.response.data.error.message });
            } else {
                notification.error({ message: 'Error Code: ' + fundResponse.response.data.error.error_number, description: fundResponse.response.data.error.message, placement: 'top' });
            }
        }
        if (fundResponse.data && fundResponse.data.company_wallet_fund_status_id) {
            if (fundResponse.data.company_wallet_fund_status_id === 1) {
                setState({ message: 'Pending Funds' });
            }
            if (fundResponse.data.company_wallet_fund_status_id === 2) {
                setState({ message: 'Funding Cancelled' });
            }
            if (fundResponse.data.company_wallet_fund_status_id === 3) {
                setState({ message: 'Funding Complete' });
            }
            toggleContentIndex();
        }
        setState({ loadingFund: false });
    };

    const handleEasyTransfer = async () => {
        try {
            setState({ loadingPayment: true, showModal: false });
            setState({ contentIndex: 0 });
            const body = {
                type: "FUND_WALLET",
                amount: amountEntered,
                currency: currency,
                description: description,
                fundMethodId: 2,
            }
            const response = await apiRequest(`${APIROUTES.post.accounting_create_payment}`, 'POST', body);
            if (response.success) {
                setState({ contentIndex: 0, currency: undefined, description: undefined, loadingFund: false });
                window.location.href = response?.response?.data?.hostedUrl;
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch {
            notification.error({ message: 'Something went wrong!', placement: 'top' })
        }
        finally {
            setState({ loadingPayment: false });
        }

    }

    const modalContent = [
        (
            <>
                <Row justify="center">
                    <Col>
                        <Typography.Text className="bold fs-18px dark-green">Funding Confirmation</Typography.Text>
                    </Col>
                </Row>
                <Row justify="center" className="m-t-10">
                    <Col>
                        <Typography.Text className="light fs-18px dark-green center-align-text">
                            If you do not have sufficient balance in your account, please make a payment to the following account:
                        </Typography.Text>
                    </Col>
                </Row>
                <Row className='m-t-10' gutter={[24, 12]} justify='center'>
                    {volopaAccountDetails?.funding_accounts?.find(account => account?.payment_type === "regular") &&
                        <Col>
                            <Button type='primary' {...(fundingLimitReached === true && { ghost: true })} onClick={(e) => updateFundingDetails(e, false, volopaAccountDetails)}>
                                Regular/Faster Payment Up To A Value Of £250,000.00
                            </Button>
                        </Col>
                    }
                    <Col>
                        <Button type='primary' {...(fundingLimitReached === false && { ghost: true })} onClick={(e) => updateFundingDetails(e, true, volopaAccountDetails)}>
                            Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments
                        </Button>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col span={24} className='bg-light-grey rounded p-10'>
                        <Row gutter={12}>
                            <Col span={14}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Account Holder Name</Typography.Text>
                                    <Typography.Text className="muli light fs-18px">{volopaAccountHolderName}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={10}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountSortCodeLabel}</Typography.Text>
                                    <Typography.Text className="muli light fs-18px">{volopaAccountSortCode}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        {fundingLimitReached &&
                            <Row className='m-t-10' gutter={12}>
                                <Col span={14}>
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountIban}</Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={10}>
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSwift}</Typography.Text>
                                    </Space>
                                </Col>
                            </Row>
                        }
                        <Row className="m-t-10" gutter={12}>
                            <Col span={14}>
                                {fundingLimitReached ?
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                    </Space> :
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                        <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                    </Space>
                                }
                            </Col>
                            <Col span={10}>
                                {!fundingLimitReached ?
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountCountry}</Typography.Text>
                                    </Space> :
                                    <Space direction='vertical' size={0}>
                                        <Typography.Text className='muli semi-bold dark-green fs-18px'>Mandatory Funding Reference</Typography.Text>
                                        <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountPaymentRef}</Typography.Text>
                                    </Space>
                                }
                            </Col>
                        </Row>
                        <Row className="m-t-10" gutter={16}>
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Bank Name</Typography.Text>
                                    <Typography.Text className="muli light fs-18px">{volopaAccountName}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="m-t-10" gutter={16}>
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Bank Address</Typography.Text>
                                    <Typography.Text className="muli light fs-18px">{volopaAccountAddress}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        {fundingLimitReached &&
                            <Row className="m-t-10">
                                <Col>
                                    <Typography.Text className='muli semi-bold dark-green fs-12px'>*Please note that the Mandatory Funding Reference is required to ensure we identify your funds efficiently.</Typography.Text>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
                <Row className="m-t-10" gutter={16}>
                    <Col span={16}>
                        <Row>
                            <Col>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Typography.Text className="muli light fs-18px dark-green"><Image src={FLAGS[form.getFieldValue("currency")]} preview={false} width={20} /> {form.getFieldValue("currency")} {accountingFormat(form.getFieldValue("amount"))}</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <Col>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Typography.Text className="muli light fs-18px dark-green">{form.getFieldValue("description")}</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-10" gutter={16}>
                    <Col span={24}>
                        <Space direction="vertical" size={0}>
                            <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                            <Typography.Text className="muli light fs-18px dark-green">{
                                form.getFieldValue("method") === OPT_FUNDING_METHOD.EASY_TRANSFER ?
                                    'Easy Transfer' :
                                    form.getFieldValue("method") === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS ?
                                        'Manual Push Funds' :
                                        "Volopa Collection Account"
                            }</Typography.Text>
                        </Space>
                    </Col>
                </Row>
                <Form
                    layout="vertical"
                    onFinish={submitFundingRequest}
                    form={modalForm}
                >
                    <Row className="m-t-20" gutter={16}>
                        <Col span={24} className='center-align-text'>
                            <Space size={108}>
                                <Button type="primary" onClick={toggleModal}>Cancel</Button>
                                <Button type="primary" htmlType="submit" loading={loadingFund}>Confirm</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </>
        ),
        (
            <>
                <Row>
                    <Col span={24}>
                        <Typography.Text type="success" className="medium fs-18px center-align-text">{message}</Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col span={24} className='bg-light-grey rounded p-10'>
                        <Row>
                            <Col span={16}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Amount</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green"> <Image src={FLAGS[form.getFieldValue("currency")]} preview={false} width={20} /> {form.getFieldValue("currency")} {form.getFieldValue("amount")}</Typography.Text>
                                </Space>
                            </Col>
                            <Col span={8}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Description</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{form.getFieldValue("description")}</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="m-t-10">
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Method</Typography.Text>
                                    <Typography.Text className="muli light fs-18px dark-green">{
                                        form.getFieldValue("method") === OPT_FUNDING_METHOD.EASY_TRANSFER ?
                                            'Easy Transfer' :
                                            form.getFieldValue("method") === OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS ?
                                                'Manual Push Funds' :
                                                "Volopa Collection Account"
                                    }</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-20">
                    {form.getFieldValue("method") === OPT_FUNDING_METHOD.EASY_TRANSFER ? <Col span={24} className='center-align-text'>
                        <Space size={108}>
                            <Button type="primary" onClick={() => { toggleModal() }}>Cancel</Button>
                            <Button type="primary" onClick={handleEasyTransfer}>Confirm</Button>
                        </Space>
                    </Col> :
                        <Col span={24} className='center-align-text'>
                            <Space size={108}>
                                <Button type="primary" onClick={() => { toggleModal(); navigate(URLS.WalletDashboard) }}>Dashboard</Button>
                                <Button type="primary" onClick={() => { setState({ contentIndex: 0 }); toggleModal(); }}>Fund More</Button>
                            </Space>
                        </Col>}
                </Row>
            </>
        )
    ]
    const props = {
        title: 'Fund Your Company Wallet',
        text: [
            '- You can fund your Company Wallet by bank transfer',
            '- Funds will be added to the Company Wallet on receipt'
        ]
    };

    const filterSelect = (input, option) => {
        if (option.children)
            if (option.children.props)
                return option.children.props.children[1].toLowerCase().includes(input.toLowerCase());
            else
                return option.children.toLowerCase().includes(input.toLowerCase());
        else if (option.label)
            return option.label.toLowerCase().includes(input.toLowerCase())
    };

    const onCurrencyChange = (value) => {
        setState({ currency: value })
    };

    const onDescriptionChange = (value) => {
        setState({ description: value })
    };

    const handlePaymentConfirmationPage = async () => {
        try {
            setState({ loadingPaymentRequest: true, transactionComplete: false });
            const response = await apiRequest(`${APIROUTES.get.easy_transfer_getBooked_payment}${query.get('paymentRequestId')}`, 'GET');
            if (response && response.success) {
                form.setFieldValue('currency', response?.data?.paymentDetail?.amountDetails?.currency)
                form.setFieldValue('amount', response?.data?.paymentDetail?.amountDetails?.amountToPay)
                form.setFieldValue('description', response?.data?.paymentDetail?.reference)
                form.setFieldValue('method', 'Easy Transfer')
                setState({
                    contentIndex: 1,
                    message: 'Fund via Easy Transfer (Open Banking)',
                    showModal: true,
                    amount: response?.data?.paymentDetail?.amountDetails?.amountToPay,
                    currency: response?.data?.paymentDetail?.amountDetails?.currency,
                    description: response?.data?.paymentDetail?.reference,
                })
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch (error) {
            notification.error({ message: 'Something Went Wrong!', placement: 'top' })
        } finally {
            setState({ loadingPaymentRequest: false });
        }
    }

    return (
        <>
            <Spin spinning={loadingPayment || loadingPaymentRequest || accountStatusFetching}>
                {transactionComplete ? (
                    <YapilyCallbackComponent
                        transactionStatus={transactionStatus}
                        returnToDashboardText={"Return to Dashboard"}
                        goToHistoryText={transactionStatus === 'COMPLETED' ? "Funding History" : "View Payment"}
                        goToHistory={!(transactionStatus === 'COMPLETED' || transactionStatus === 'PENDING') ? handlePaymentConfirmationPage : () => { toggleModal(); navigate(URLS.FundingHistory); setState({ transactionComplete: false, showModal: false }) }}
                        returnToDashboard={() => { navigate(URLS.WalletDashboard); setState({ transactionComplete: false, showModal: false }) }}
                    />
                ) : (<>
                    <Modal
                        open={showModal}
                        onCancel={toggleModal}
                        closable={false}
                        footer={null}
                        bodyStyle={{ padding: '0px' }}
                        getContainer={false}
                        destroyOnClose>
                        <Card.Grid className="full-percent-width rounded">
                            {modalContent[contentIndex]}
                        </Card.Grid>
                    </Modal>
                    <Row>
                        <Col span={24}>
                            <PageDescription {...props} />
                        </Col>
                    </Row>
                    <Row className="m-t-10" gutter={[16, 10]}>
                        <Col xxl={6} xl={8} lg={10} md={12} sm={16} xs={24}>
                            <Typography.Text className="fs-18px medium dark-green">Fund Wallet By Amount</Typography.Text>
                            <Card hoverable className="b-g hover-no-border">
                                <Form layout="vertical" form={form}>
                                    <Row gutter={6}>
                                        <Col flex='90px'>
                                            <Form.Item name="currency">
                                                <Select
                                                    className='dark-green full-percent-width'
                                                    showSearch
                                                    onChange={onCurrencyChange}
                                                    filterOption={filterSelect}
                                                    disabled={
                                                        !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                                                            !!accountStatus?.suspended_accounts?.cards
                                                    }
                                                >
                                                    <Select.Option value="GBP" className='p-l-10'>
                                                        <Space size={3} align='center'>
                                                            <Image src={FLAGS['GBP']} preview={false} width={20} /> GBP
                                                        </Space>
                                                    </Select.Option>
                                                    <Select.Option value="EUR" className='p-l-10'>
                                                        <Space size={3} align='center'>
                                                            <Image src={FLAGS['EUR']} preview={false} width={20} /> EUR
                                                        </Space>
                                                    </Select.Option>
                                                    <Select.Option value="USD" className='p-l-10'>
                                                        <Space size={3} align='center'>
                                                            <Image src={FLAGS['USD']} preview={false} width={20} /> USD
                                                        </Space>
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col flex='auto'>
                                            <Form.Item name="amount">
                                                <InputNumber
                                                    placeholder='Enter Amount'
                                                    className="full-percent-width"
                                                    formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                    parser={(val) => numberFormat(val)}
                                                    min={0}
                                                    controls={false}
                                                    disabled={
                                                        !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                                                            !!accountStatus?.suspended_accounts?.cards
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xxl={16} xl={16} lg={16} md={16} sm={16} xs={24}>
                                            <Form.Item
                                                label={<span className="fs-18px muli semi-bold">Funding Method</span>}
                                                name="method"
                                            >
                                                <Select
                                                    className='dark-green full-percent-width'
                                                    showSearch
                                                    defaultValue={OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS}
                                                    filterOption={filterSelect}
                                                    disabled={
                                                        !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                                                            !!accountStatus?.suspended_accounts?.cards
                                                    }
                                                >
                                                    <Select.Option value={OPT_FUNDING_METHOD.MANUAL_PUSH_FUNDS}>
                                                        <Tooltip placement="right" title="Once you confirm the funding transaction details, we will supply the Account details to fund the Company Wallet.">
                                                            Manual Push Funds
                                                        </Tooltip>
                                                    </Select.Option>
                                                    <Select.Option value={OPT_FUNDING_METHOD.EASY_TRANSFER} disabled={disableEasyTransfer}>
                                                        <Tooltip placement="right" title="We will redirect you to your banking platform to authorise the funding transaction (Only available when funding in GBP or EUR).">
                                                            Easy Transfer
                                                        </Tooltip>
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={OPT_FUNDING_METHOD.VOLOPA_COLLECTION_ACCOUNT}
                                                        disabled={!config?.service_statuses?.payments_status || !config?.service_statuses?.collections_status}
                                                    >
                                                        <Tooltip placement="right" title="Once you confirm the funding transaction details, we will supply the Account details to fund the Company Wallet.">
                                                            Volopa Collection Account
                                                        </Tooltip>
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row align="bottom" gutter={[16, 16]} justify='space-between'>
                                        <Col xxl={16} xl={16} lg={16} md={16} sm={16} xs={24}>
                                            <Form.Item
                                                label={<span className="fs-18px muli semi-bold">Description</span>}
                                                className='m-b-0'
                                                name="description"
                                                onChange={onDescriptionChange}
                                            >
                                                <Input
                                                    disabled={
                                                        !config.is_eea ? !!accountStatus?.suspended_accounts?.payments :
                                                            !!accountStatus?.suspended_accounts?.cards
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Button
                                                type="primary"
                                                onClick={toggleModal}
                                                disabled={!(currency && amountEntered && description?.target?.value) || !fundingAccountExists}
                                            >
                                                Confirm
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 4 }} lg={14} md={16} sm={24} xs={24}>
                            <Typography.Text className="medium fs-18px dark-green">Company Wallet Balance</Typography.Text>
                            <Card hoverable className="b-g hover-no-border" data-testid="container">
                                <CompanyWalletBalance />
                            </Card>
                        </Col>
                    </Row>
                </>)}
            </Spin>
        </>
    );
}

export default FundWallet;
